* {
  font-family: 'Montserrat', sans-serif;
}

button {
  border: none;
  background: none;
}

a {
  padding: 0;
  margin: 0;
  text-decoration: none;
  color: currentColor;
}

p {
  padding: 0;
  cursor: default;
}

h1 {
  cursor: default;
}

h2 {
  cursor: default;
}

ul {
  cursor: default;
}

li {
  cursor: default;
}